import { Recursos } from './recursos';

export class AcessoPerfil {
  static readonly administracao = {
    perfis: [Recursos.ADMINISTRADOR_SGV]
  }
  static readonly adjunto = {
    perfis: [Recursos.ADJUNTO_SGV]
  }
  static readonly odometro = {
    perfis: [Recursos.ADMINISTRADOR_SGV, Recursos.ADJUNTO_SGV]
  }
  static readonly policalArea = {
    perfis: [Recursos.POLICIAL_AREA_SGV]
  }
  static readonly policialComplexo = {
    perfis: [Recursos.POLICIAL_COMPLEXO_SGV]
  }
}

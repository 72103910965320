import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecursoGuard } from './core/service/recurso-guard.service';
import { AppLoginComponent } from './app-login/applogin.component';
import { Recursos } from './core/utils/recursos';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: ''
    },
    {
        path: 'app-login',
        component: AppLoginComponent
    },
     // {
  //     path: 'vistorias',
  //     canActivate: [],
  //     loadChildren: () =>
  //         import('./vistoria/vistoria.module').then((m) => m.VistoriaModule),
  // },
  // {
  //     path: 'relatorios',
  //     canActivate: [],
  //     loadChildren: () =>
  //         import('./relatorio/relatorio.module').then((m) => m.RelatorioModule),
  // },
  {
    path: 'gestao/tipo-defeitos',
    canActivate: [RecursoGuard],
    data: {
      perfis: [
        Recursos.ADMINISTRADOR_SGV,
      ]
    },
    loadChildren: () =>
      import('./tipo-defeito-vistoria/tipo-defeito-vistoria.module').then((m) => m.TipoDefeitoVistoriaModule),
  },
  {
    path: 'gestao/subitem',
    canActivate: [RecursoGuard],
    data: {
      perfis: [
        Recursos.ADMINISTRADOR_SGV,
      ]
    },
    loadChildren: () =>
      import('./subitem-vistoria/subitem-vistoria.module').then((m) => m.SubitemVistoriaModule),
  },
  {
    path: 'gestao/item',
    canActivate: [RecursoGuard],
    data: {
      perfis: [
        Recursos.ADMINISTRADOR_SGV,
      ]
    },
    loadChildren: () =>
      import('./item-vistoria/item-vistoria.module').then((m) => m.ItemVistoriaModule),
  },
  {
    path: 'gestao/tipo',
    canActivate: [RecursoGuard],
    data: {
      perfis: [
        Recursos.ADMINISTRADOR_SGV,
      ]
    },
    loadChildren: () =>
      import('./tipo-vistoria/tipo-vistoria.module').then((m) => m.TipoVistoriaModule),
  },
  {
    path: 'diferenca-odometro',
    // canActivate: [RecursoGuard],
    data: {
      perfis: [
        Recursos.ADMINISTRADOR_SGV,
        Recursos.ADJUNTO_SGV
      ]
    },
    loadChildren: () =>
      import('./odometro/odometro-vistoria.module').then((m) => m.OdometroVistoriaModule),
  },
  {
    path: 'controle-vistoria',
    // canActivate: [RecursoGuard],
    data: {
      // perfis: [
      //   Recursos.ADMINISTRADOR_SGV,
      //   Recursos.ADJUNTO_SGV
      // ]
    },
    loadChildren: () =>
      import('./controle-vistoria/controle-vistoria.module').then((m) => m.ControleVistoriaModule),
  },
  {
    path: 'relatorio',
    // canActivate: [RecursoGuard],
    data: {
      // perfis: [
      //   Recursos.ADMINISTRADOR_SGV,
      //   Recursos.ADJUNTO_SGV
      // ]
    },
    loadChildren: () =>
      import('./relatorio/relatorio.module').then((m) => m.RelatorioModule),
  },
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [RecursoGuard],
})
export class AppRoutingModule {
}

import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-login',
    templateUrl: './applogin.component.html',
    styleUrls: []
})
export class AppLoginComponent implements OnInit {
    ngOnInit(): void {

    }

}
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Permite que o usuário acesse a rota caso tenha pelo menos uns dos recursos disponíveis na página
 */
@Injectable({
    providedIn: 'root',
})
export class RecursoGuard implements CanActivate {
    constructor(private api: ApiService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const recursosParaAtivar: string[] = route.data.perfis;
        return this.api.getApi().toPromise().then(
          (r) => {
            const retorno = r.perfis.some((rUser) => recursosParaAtivar.includes(rUser.nome));
    
            if (retorno) {
              return retorno;
            }else {;
              return retorno;
            }
          }).catch(
          (e) => {
            console.log(e);
            return false;
          }
        );
      }
}

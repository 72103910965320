import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '../../../environments/environment';
import {Usuario} from '../models/usuario.model';
import { Api } from './models/api.model';
import { shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export const OAUTH_CONFIG: AuthConfig = {
    issuer: `${environment.sso_url}`,
    redirectUri: `${environment.sso_redirect_uri}`,
    clientId: `${environment.sso_client_id}`,
    responseType: 'code',
    showDebugInformation: false,
    scope: 'openid profile',
    requireHttps: false,
};

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    user: Subject<Usuario> = new Subject<Usuario>();
    private api: BehaviorSubject<Api> = new BehaviorSubject<Api>(null);
    private api$: Observable<Api>;

    constructor(private oauthService: OAuthService, private http: HttpClient) {
    }

    login(): void {
        this.oauthService.configure(OAUTH_CONFIG);
        this.oauthService.setStorage(localStorage);
        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
            if (!this.oauthService.hasValidAccessToken()) {
                this.oauthService.initLoginFlow();
            } else {
                this.oauthService.setupAutomaticSilentRefresh();
                this.oauthService.loadUserProfile().then((up) => {
                  this.user.next({
                        name: up?.given_name?.replace(/[\d]/g, '').trim(),
                        cpf: up?.given_name?.replace(/[^\d]/g, '').trim(),
                    });
                }).catch((err) => {
                  console.log('Load User Profile erro: ' + JSON.stringify(err));
                  this.oauthService.initLoginFlow();
                });
            }
        });
    }

    getApi(): Observable<Api> {
        if (this.api$ == null) {
            this.api$ = this.http
                .get<Api>(`${environment.api_url}/`)
                .pipe(shareReplay(1));
        }
        return this.api$;
    }

    setApi(api: Api): void {
        this.api.next(api);
    }

    getUser(): Observable<Usuario> {
        return this.user;
    }

    // getAuthUser(): Observable<Usuario> {
    //     this.oauthService.loadUserProfile().then((up) => {
    //         this.user.next({
    //             name: up?.given_name?.replace(/[\d]/g, '').trim(),
    //             cpf: up?.given_name?.replace(/[^\d]/g, '').trim(),
    //         });
    //     });

    //     return this.getUser();
    // }

    logout(): void {
        this.oauthService.logOut();
    }

    async getAccessTokenWithAutoRefresh(): Promise<string> {
        if (!this.oauthService.hasValidAccessToken()) {
            try {
                const result = await this.oauthService.refreshToken();
                if (result) {
                    return this.oauthService.getAccessToken();
                }
            } catch (error) {
                console.error('Erro na renovação do token de acesso:', error);
            }
        }

        return this.oauthService.getAccessToken();
    }
}

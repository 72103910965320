export class Recursos {
  static readonly ADMINISTRADOR_SGV =
    'ADMINISTRADOR_SGV';
  static readonly ADJUNTO_SGV =
    'ADJUNTO_SGV';
  static readonly POLICIAL_AREA_SGV =
    'POLICIAL_AREA_SGV';
  static readonly POLICIAL_COMPLEXO_SGV =
    'POLICIAL_COMPLEXO_SGV';
}

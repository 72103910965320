import { Component, OnInit } from '@angular/core';
import { MenuDefinition } from './shared/components/menu/menu-definition';
import { AuthService } from './core/service/auth.service';
import { Observable } from 'rxjs';
import { Usuario } from './core/models/usuario.model';
import { ApiService } from './core/service/api.service';
import { Api } from './core/service/models/api.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AcessoPerfil } from './core/utils/acessoPerfil';
import { Recursos } from './core/utils/recursos';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    isAppLoginRoute: boolean;

    title = 'Vistoria';
    api: Api;
    usuario: Usuario;
    menuItens: MenuDefinition[] = [];
    perfilLogado: string;

    constructor(
        private authService: AuthService,
        private router: Router,
        private apiService: ApiService
    ) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            let url = event.url;
            this.isAppLoginRoute = event.url === '/app-login';

            if (!this.isAppLoginRoute) {
                this.authService.login();
                if (!this.validUrl(url)) {
                    this.authService.getUser().subscribe((usuario) => {
                        this.menuItens = [];
                        this.usuario = usuario;
                        this.buildMenu();
                        // console.log(this.menuItens);
                    });
                }

            }
        });


    }

    validUrl(url: string) {
        let endIndex = url.indexOf("/app-login");
        if (endIndex !== -1) {
            // let substring = url.substring(0, endIndex);
            return true;
        } else {
            return false;
        }
    }

    buildMenu() {
        this.apiService.getApi().subscribe((api) => {
            this.api = api;
            // this.validarPerfilUsuario();
            this.createMenuHome();
            this.createMenuAdministrador();
            this.createMenuOdometro();
            this.createMenuRelatorio();
        });
    }

    private createMenuHome() {
        this.menuItens.push({
            label: 'Pagina Inicial',
            icon: 'home',
            route: ''

        },

            // {
            //     label: 'Relatórios',
            //     icon: 'assignment',
            //     subitens: [{
            //         label: 'Gerar Relatório',
            //         icon: 'assignment',
            //         route: 'relatorio',
            //     },]
            // },

            // {
            //     label: 'Adjunto',
            //     icon: 'person',
            //     subitens: [{
            //         label: 'Controle de Vistoria',
            //         icon: 'person',
            //         route: 'controle-vistoria',
            //     },]
            // },
        );
    }

    private createMenuRelatorio() {
        this.menuItens.push(
            {
                label: 'Relatórios',
                icon: 'assignment',
                subitens: [{
                    label: 'Gerar Relatório',
                    icon: 'assignment',
                    route: 'relatorio',
                },]
            },

            {
                label: 'Adjunto',
                icon: 'person',
                subitens: [{
                    label: 'Controle de Vistoria',
                    icon: 'person',
                    route: 'controle-vistoria',
                },]
            },
        );
    }

    private createMenuAdministrador() {
        if (
            this.api.perfis
                .map((r) => r.nome)
                .includes(AcessoPerfil.administracao.perfis[0])
        ) {
            this.menuItens.push(
                {
                    label: 'Gestão de Vistoria',
                    icon: 'settings_applications',
                    perfis: AcessoPerfil.administracao.perfis,
                    subitens: [
                        {
                            label: 'Tipo de Defeitos',
                            icon: 'assignment',
                            route: 'gestao/tipo-defeitos',
                            perfis: AcessoPerfil.administracao.perfis
                        },
                        {
                            label: 'Subitem de Vistoria',
                            icon: 'assignment',
                            route: 'gestao/subitem',
                            perfis: AcessoPerfil.administracao.perfis
                        },
                        {
                            label: 'Item de Vistoria',
                            icon: 'assignment',
                            route: 'gestao/item',
                            perfis: AcessoPerfil.administracao.perfis
                        },
                        {
                            label: 'Tipo de Vistoria',
                            icon: 'assignment',
                            route: 'gestao/tipo',
                            perfis: AcessoPerfil.administracao.perfis
                        },
                    ],
                },
                // {
                //     label: 'Relatórios',
                //     icon: 'assignment',
                //     route: '/relatorios/pesquisar'
                // },
            );
        }

    }

    createMenuOdometro() {
        if (
            this.api.perfis
                .map((r) => r.nome)
                .includes(AcessoPerfil.odometro.perfis[0])
            ||
            this.api.perfis
                .map((r) => r.nome)
                .includes(AcessoPerfil.odometro.perfis[1])
        ) {
            this.menuItens.push({
                label: 'Odômetro',
                icon: 'slow_motion_video',
                route: 'diferenca-odometro',
                perfis: AcessoPerfil.odometro.perfis
            });
        }
    }

    private validarPerfilUsuario() {
        if (this.api.perfis.length > 1) {
            this.perfilLogado = 'EXCLUSIVO';
        }
        else if (this.api.perfis.length === 0) {
            this.perfilLogado = 'SEM PERFIL';
        } else {
            this.perfilLogado = this.convertPerfilUsuario(this.api.perfis[0].nome);
        }
    }

    private convertPerfilUsuario(nome: string): string {
        let nomeReturn;
        switch (nome) {
            case Recursos.ADMINISTRADOR_SGV:
                nomeReturn = 'ADMINISTRADOR';
                break;
            case Recursos.ADJUNTO_SGV:
                nomeReturn = 'ADJUNTO'
                break;
            case Recursos.POLICIAL_AREA_SGV:
                nomeReturn = 'POLICIAL DE ÁREA'
                break;
            case Recursos.POLICIAL_COMPLEXO_SGV:
                nomeReturn = 'POLICIAL COMPLEXO ADM'
                break;
        }
        return nomeReturn;
    }

    handleLogout() {
        this.authService.logout();
    }


    get sobreInfo(): string {
        return `<p>SGV - Sistema de Gestão de Vistoria</p>
        <p>Versão API: ${this.api?.buildVersion}</p>`;
    }

    get contatoInfo(): string {
        return `Qualquer dúvida, entrar em contato com:<br /><br />
            <b>DITEL/SAU:</b> 85800 ou 31905800<br />`;
    }
}
